import Carousel from "bootstrap/js/dist/carousel";
import Collapse from "bootstrap/js/dist/collapse";
import Offcanvas from "bootstrap/js/dist/offcanvas";
import Tab from "bootstrap/js/dist/tab";

import lazySizes from 'lazysizes';
import axios from 'axios';
import {jarallax} from 'jarallax';
import {Chart, registerables} from 'chart.js/auto';
import {WordCloudChart} from 'chartjs-chart-wordcloud';

document.addEventListener("DOMContentLoaded", function () {

	/* ~~~~~~~~~~~~~~~~ Paywall ~~~~~~~~~~~~~~~~ */
	(function () {

		const paywallContainer = document.querySelector('.mg-wp-zeno-login-wall');

		if (paywallContainer) {
			const brandArrowRight = document.createElement('div');
			brandArrowRight.classList.add('brand-arrow', 'brand-arrow--right', 'd-none', 'd-lg-block');
			brandArrowRight.innerHTML = '<img src="/theme/img/arrow-right-blue.svg" /><div class="brand-arrow__text">1e maand<br>gratis</div>';

			const brandArrowDown = document.createElement('div');
			brandArrowDown.classList.add('brand-arrow', 'brand-arrow--down', 'd-block', 'd-lg-none');
			brandArrowDown.innerHTML = '<img src="/theme/img/arrow-down-blue.svg" /><div class="brand-arrow__text">1e maand<br>gratis</div>';

			paywallContainer.appendChild(brandArrowRight);
			paywallContainer.appendChild(brandArrowDown);
		}

	})();

	/* Subscription Custom Tab Listeners */
	(function () {

		let updateProgressBar = function (step) {

			const progresBarStep1 = document.querySelector('.js-progbar-step1');
			const progresBarStep2 = document.querySelector('.js-progbar-step2');
			const progresBarStep3 = document.querySelector('.js-progbar-step3');

			const progresBarText1 = document.querySelector('.js-progbar-text1');
			const progresBarText2 = document.querySelector('.js-progbar-text2');
			const progresBarText3 = document.querySelector('.js-progbar-text3');

			if (step === 1) {

				// step 1
				progresBarStep1.classList.add('progbar__step--active');
				progresBarStep1.classList.remove('progbar__step--done');
				progresBarText1.classList.add('progbar__text--active');

				// step 2
				progresBarStep2.classList.remove('progbar__step--active');
				progresBarStep2.classList.remove('progbar__step--done');
				progresBarText2.classList.remove('progbar__text--active');

				// step 3
				progresBarStep3.classList.remove('progbar__step--active');
				progresBarStep3.classList.remove('progbar__step--done');
				progresBarText3.classList.remove('progbar__text--active');

			} else if (step === 2) {

				// step 1
				progresBarStep1.classList.remove('progbar__step--active');
				progresBarStep1.classList.add('progbar__step--done');
				progresBarText1.classList.remove('progbar__text--active');

				// step 2
				progresBarStep2.classList.add('progbar__step--active');
				progresBarStep2.classList.remove('progbar__step--done');
				progresBarText2.classList.add('progbar__text--active');

				// step 3
				progresBarStep3.classList.remove('progbar__step--active');
				progresBarStep3.classList.remove('progbar__step--done');
				progresBarText3.classList.remove('progbar__text--active');

			} else if (step === 3) {

				// step 1
				progresBarStep1.classList.remove('progbar__step--active');
				progresBarStep1.classList.add('progbar__step--done');
				progresBarText1.classList.remove('progbar__text--active');

				// step 2
				progresBarStep2.classList.remove('progbar__step--active');
				progresBarStep2.classList.add('progbar__step--done');
				progresBarText2.classList.remove('progbar__text--active');

				// step 3
				progresBarStep3.classList.add('progbar__step--active');
				progresBarStep3.classList.remove('progbar__step--done');
				progresBarText3.classList.add('progbar__text--active');

			} else {
				//
			}

			let myOffset = (window.innerWidth > 992) ? 90 : 75;
			scrollIntoViewWithOffset('#js-progressbar', myOffset);

		}

		const scrollIntoViewWithOffset = (selector, offset) => {
			window.scrollTo({
				behavior: 'smooth',
				top:
					document.querySelector(selector).getBoundingClientRect().top -
					document.body.getBoundingClientRect().top -
					offset,
			})
		}

		// step 1
		const step1Buttons = document.querySelectorAll('.js-step-1-button');
		step1Buttons.forEach((item) => {
			item.addEventListener('click', function (event) {

				// update progress bar
				updateProgressBar(1)

				// activate Tab
				const step1El = document.querySelector('#step1-tab');
				const step1Tab = new Tab(step1El);
				step1Tab.show()
			});
		});

		// step 2 (step back from step 3)
		const step2Buttons = document.querySelectorAll('.js-step-2-button');
		step2Buttons.forEach((item) => {
			item.addEventListener('click', function (event) {

				// get active category
				let catId = item.dataset.catId;

				if (catId != 'none') {

					// update progress bar
					updateProgressBar(2);

					// activate Tab
					const step2El = document.querySelector('#step2' + catId + '-tab');
					const step2Tab = new Tab(step2El);
					step2Tab.show()
				} else {
					alert("Let op: kies eerst één van onze abonnementen.");
				}

			});
		});

		// step 2 - basis
		const step2basisButtons = document.querySelectorAll('.js-step-2-basis-button');
		step2basisButtons.forEach((item) => {
			item.addEventListener('click', function (event) {
				// update progress bar
				updateProgressBar(2)
				// activate Tab
				const step2basisEl = document.querySelector('#step2basis-tab');
				const step2basisTab = new Tab(step2basisEl);
				step2basisTab.show()
			});
		});


		// step 2 - plus
		const step2plusButtons = document.querySelectorAll('.js-step-2-plus-button');
		step2plusButtons.forEach((item) => {
			item.addEventListener('click', function (event) {
				// update progress bar
				updateProgressBar(2)
				// activate Tab
				const step2plusEl = document.querySelector('#step2plus-tab');
				const step2plusTab = new Tab(step2plusEl);
				step2plusTab.show()
			});
		});

		// step 2 - premium
		const step2premiumButtons = document.querySelectorAll('.js-step-2-premium-button');
		step2premiumButtons.forEach((item) => {
			item.addEventListener('click', function (event) {
				// update progress bar
				updateProgressBar(2)
				// activate Tab
				const step2premiumEl = document.querySelector('#step2premium-tab');
				const step2premiumTab = new Tab(step2premiumEl);
				step2premiumTab.show()
			});
		});

		// step 3
		const step3Buttons = document.querySelectorAll('.js-step-3-button');
		step3Buttons.forEach((item) => {

			item.addEventListener('click', function (event) {

				// update progress bar
				updateProgressBar(3)
				// activate Tab
				const step3El = document.querySelector('#step3-tab');
				const step3Tab = new Tab(step3El);
				step3Tab.show()

				// activate product
				let productId = item.dataset.productId;
				let activeProduct = document.querySelector('#product-' + productId);

				// pass the selected category tot step2 button
				let catId = item.dataset.catId;
				let step2button = document.querySelector('.js-step-2-button');
				step2button.setAttribute('data-cat-id', catId);

				// reset all products
				let step3products = document.querySelectorAll('.js-step3-card');
				step3products.forEach((product) => {
					product.style.display = 'none';
				});

				// activate selected product
				if (activeProduct) {
					activeProduct.style.display = 'block'
				}

			});
		});
	})();

	/* ~~~~~~~~~~~~~~~~ Page Loader ~~~~~~~~~~~~~~~~ */
	(function () {
		let preLoader = document.querySelector('.js-preloader');
		if (preLoader) {
			setTimeout(function () {
				preLoader.classList.add('loading');
				setTimeout(function () {
					preLoader.classList.remove('loading');
					preLoader.classList.add('loaded');
				}, 600);
			}, 300);
		}
	})();

	/* ~~~~~~~~~~~~~~~~ cookie for Join CTA (orange bar) ~~~~~~~~~~~~~~~~ */
	(function () {
		let getCookie = function (name) {
			let value = `; ${document.cookie}`;
			let parts = value.split(`; ${name}=`);
			if (parts.length === 2) return parts.pop().split(';').shift();
		}

		let setCookie = function (cname, cvalue, cpath, cmaxage) {
			document.cookie = `${cname}=${cvalue}; path=${cpath}; max-age=${cmaxage};`;
		}

		const joinCtaBar = document.querySelector(".js-joincta");

		if (joinCtaBar !== null) {

			const cName = '_theo_join_cta_banner';
			const cValue = 'true';
			const cPath = '/';
			const cMaxAge = 60 * 60 * 24 * 7;
			const cEmpty = 'undefined'

			let cookieValue = getCookie(cName);

			if (typeof cookieValue === cEmpty || (typeof cookieValue !== cEmpty && cookieValue !== cValue)) {
				setCookie(cName, cValue, cPath, cMaxAge);
				joinCtaBar.style.display = 'block';
			}
		}
	})();

	/* ~~~~~~~~~~~~~~~~ toggle search form ~~~~~~~~~~~~~~~~ */
	(function () {
		let searchButton = document.querySelector('.js-search-btn');
		if (searchButton) {
			searchButton.addEventListener('click', function (e) {
				let searchbar = document.querySelector('.js-searchbar');
				let inputfield = document.querySelector('#searchfield');

				if (searchbar.classList.contains('searchbar--open')) {
					searchbar.classList.remove('searchbar--open');
				} else {
					searchbar.classList.add('searchbar--open');
					inputfield.focus();
				}

			});
		}
	})();

	/* ~~~~~~~~~~~~~~~~  Shrink Navbar ~~~~~~~~~~~~~~~~ */
	(function () {
		let navbarShrink = function () {
			const navbarFixed = document.body.querySelector('.navbar.sticky-top');
			if (navbarFixed) {

				let offset = 100; // topbar height
				let offsetMargin = 15; // make shure the navbar is not toggled too fast

				if (window.scrollY > offset + offsetMargin) {
					navbarFixed.classList.add('navbar-scrolled')
				}
				if (window.scrollY < offset - offsetMargin) {
					navbarFixed.classList.remove('navbar-scrolled')
				}
			}

		};
		navbarShrink();
		document.addEventListener('scroll', navbarShrink);
	})();

	/* ~~~~~~~~~~~~~~~~  Nav dropdown on hover ~~~~~~~~~~~~~~~~ */
	(function () {
		if (window.innerWidth > 992) {
			document.querySelectorAll('.navbar .nav-item').forEach(function (everyitem) {
				everyitem.addEventListener('mouseover', function (e) {
					let el_link = this.querySelector('a[data-bs-toggle]');
					if (el_link != null) {
						let nextEl = el_link.nextElementSibling;
						el_link.classList.add('show');
						nextEl.classList.add('show');
					}
				});
				everyitem.addEventListener('mouseleave', function (e) {
					let el_link = this.querySelector('a[data-bs-toggle]');
					if (el_link != null) {
						let nextEl = el_link.nextElementSibling;
						el_link.classList.remove('show');
						nextEl.classList.remove('show');
					}
				})
			});
		}
	})();

	/* ~~~~~~~~~~~~~~~~  Jarallax ~~~~~~~~~~~~~~~~ */
	(function () {
		jarallax(document.querySelectorAll(".js-jarallax"));
	})();

	/* ~~~~~~~~~~~~~~~~  lazySizes ~~~~~~~~~~~~~~~~ */
	lazySizes.cfg.loadMode = 1;

	/* ~~~~~~~~~~~~~~~~ Book thumbnails ~~~~~~~~~~~~~~~~ */
	(function () {
		let thumbs = document.querySelectorAll('.js-thumbnail');
		let mainImage = document.querySelector('.js-main-image');
		if (mainImage) {
			thumbs.forEach((item) => {
				item.addEventListener('click', function (event) {
					mainImage.src = this.getElementsByTagName('img')[0].src;
				});
			});
		}
	})();

	/* ~~~~~~~~~~~~~~~~ SEARCH FILTERS ~~~~~~~~~~~~~~~~ */

	(function () {
		// add collapse button to facet boxes
		let facetBoxes = document.querySelectorAll('.facetwp-facet');
		facetBoxes.forEach((facetBox) => {

			let facetBoxHeader = null;
			let prevSibling = facetBox.previousElementSibling;
			if (prevSibling.tagName == 'H3') {
				facetBoxHeader = prevSibling;
			} else {
				if (prevSibling.classList.contains('facet-subheader')) {
					let prevSibling2 = prevSibling.previousElementSibling;
					if (prevSibling2.tagName == 'H3') {
						facetBoxHeader = prevSibling2;
					}
				}
			}
			if (facetBoxHeader) {
				facetBoxHeader.innerHTML = facetBoxHeader.innerHTML + '<a href="javascript:void(0)" class="js-facet-box-collapse open"><i class="fal fa-angle-up"></i></a>';
			}
		});

		// add events to collapse buttons
		let facetCollapseButtons = document.querySelectorAll('.js-facet-box-collapse');
		facetCollapseButtons.forEach((collapseButton) => {
			collapseButton.addEventListener('click', function (event) {
				let facetHeader = this.parentNode;
				if (facetHeader) {

					let facetBox = null;
					let subHeader = null;
					let nextSibling = facetHeader.nextElementSibling;
					if (nextSibling.classList.contains('facet-subheader')) {
						subHeader = nextSibling;
						let nextSibling2 = nextSibling.nextElementSibling;
						facetBox = nextSibling2;
					} else {
						facetBox = nextSibling;
					}

					if (facetBox) {
						if (this.classList.contains('open')) {
							// close box
							facetBox.style.display = 'none';
							if (subHeader) {
								subHeader.style.display = 'none';
							}
							this.classList.remove('open');
							this.innerHTML = '<i class="fal fa-angle-down"></i>'
						} else {
							// open box
							facetBox.style.display = 'block';
							if (subHeader) {
								subHeader.style.display = 'block';
							}
							this.classList.add('open');
							this.innerHTML = '<i class="fal fa-angle-up"></i>'
						}
					}
				}
			});
		});
	})();

	/* ~~~~~~~~~~~~~~~~ FAKE actions for facetwp - START ~~~~~~~~~~~~~~~~ */
	(function () {
		let filterCheckboxes = document.querySelectorAll('.facetwp-checkbox');
		let delFilter = document.querySelector('.js-del-filter');
		filterCheckboxes.forEach((item) => {
			item.addEventListener('click', function (event) {
				if (item.classList.contains('checked')) {
					item.classList.remove('checked');
				} else {
					item.classList.add('checked');
					delFilter.style.display = 'block';
				}
			});
		});
	})();

	/* ~~~~~~~~~~~~~~~~ FAKE actions for facetwp - END ~~~~~~~~~~~~~~~~ */
	(function () {
		// add a show-more button to every facet box
		let filterGroups = document.querySelectorAll('.facetwp-facet');
		filterGroups.forEach((filterGroup) => {

			if (!filterGroup.classList.contains('no-collapse')) {

				let childCount = 0;
				let childElements = Array.from(filterGroup.children);
				childElements.forEach((child) => {
					if (child.classList.contains('facetwp-checkbox')) {
						childCount++;
					}
				});
				if (childCount > 6) {
					let filterGroupId = filterGroup.dataset.name
					let showMore = '<a href="javascript:void(0)" class="js-show-more" data-id="' + filterGroupId + '"><i class="fal fa-plus"></i>Toon meer</a>';
					filterGroup.insertAdjacentHTML('beforeend', showMore);
				}
			}
			// gracefully loading checkboxes
			let children = Array.from(filterGroup.children);
			children.forEach((child) => {
				child.style.opacity = 1;
			});
		});

		// add events tot the new show-more buttons
		let showButtons = document.querySelectorAll(".js-show-more");
		let click_event = function () {
			let linkText = this.innerHTML;
			if (linkText.includes('meer')) {
				this.innerHTML = `<i class="fal fa-minus"></i> Toon minder`;
				this.parentNode.classList.remove("hide-content");
				this.parentNode.classList.remove("initial-content");
				this.parentNode.classList.add("show-content");
				this.classList.remove("closed");
				this.classList.add("open");
			} else {
				this.innerHTML = `<i class="fal fa-plus"></i> Toon meer`;
				this.parentNode.classList.remove("show-content");
				this.parentNode.classList.add("hide-content");
				this.classList.remove("open");
				this.classList.add("closed");
			}
		};
		showButtons.forEach((showButton) => {
			showButton.addEventListener('click', click_event, false);
		});

		// replace the default expand-children event, to make sure the facet box expands as well
		let expandButtons = document.querySelectorAll('.facetwp-expand');
		expandButtons.forEach((item) => {

			// replace default plus button
			item.innerHTML = '<i class="fal fa-plus"></i>';

			item.addEventListener('click', function (event) {

				// prevent other events
				event.stopPropagation();

				let parent = item.parentNode;
				let child = parent.nextElementSibling;
				if (child.classList.contains('visible')) {
					child.classList.remove('visible');
					item.innerHTML = '<i class="fal fa-plus"></i>';
				} else {
					child.classList.add('visible');
					item.innerHTML = '<i class="fal fa-minus"></i>';

					// expand the facet box!
					let facetBox = parent.parentNode;


					facetBox.classList.remove("hide-content");
					facetBox.classList.remove("initial-content");
					facetBox.classList.add("show-content");

					let showMore = facetBox.lastChild;
					if (showMore.nodeType == 1) {
						showMore.innerHTML = `<i class="fal fa-minus"></i> Toon minder`;
						showMore.classList.remove("closed");
						showMore.classList.add("open");
					}

				}
			});
		});
	})();

	/* ~~~~~~~~~~~~~~~~ TAG CLOUD ~~~~~~~~~~~~~~~~ */
	(function () {
		let myCanvas = document.querySelector('.js-tag-canvas');

		if (myCanvas) {

			// you should get this from the database
			const DATA_URL = 'https://staging5.lara7.nl/data/public/tags.json';
			const getTags = async () => {
				try {
					const response = await axios.get(DATA_URL);
					return response.data;
				} catch (errors) {
					console.error(errors);
				}
			};

			getTags().then((words) => {

				const data = {
					labels: words.map((d) => d.key),
					datasets: [
						{
							label: '',
							data: words.map((d) => 10 + d.value * 3),
						},
					],
				};

				let calculateColors = function (words) {
					return words.map((d) => "rgba(255, 255, 255, " + (0.5 + d.value / 20) + ")");
				}

				const myChart = new WordCloudChart(myCanvas.getContext('2d'), {
					data: data,
					options: {
						title: {
							display: false,
							text: 'Chart.js Word Cloud',
						},
						plugins: {
							legend: {
								display: false,
							},
							tooltip: {
								events: [],
							}
						},
						onHover: (event, chartElement) => {
							event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
						},
						color: calculateColors(words),
						hoverColor: '#685975',
						family: "Georgia, serif",
						minRotation: 0,
						maxRotation: 0,
						padding: 10
					},

				});

				function clickHandler(click) {
					const points = myChart.getElementsAtEventForMode(click, 'nearest', {intersect: true}, true);
					if (points.length) {
						const firstPoint = points[0];
						const activeTag = myChart.data.labels[firstPoint.index];
						window.location.href = '/search?tag=' + activeTag;
					}
				}

				myCanvas.onclick = clickHandler;


			});
		}
	})();

	/* ~~~~~~~~~~~~~~~~ Offcanvas Menu ~~~~~~~~~~~~~~~~ */

	(function () {
		let transitionEndEventName = getTransitionEndEventName();

		const cMenuItems = document.querySelectorAll('.cmenu__item.cmenu__item--lev1');
		cMenuItems.forEach(cMenuItem => {
			cMenuItem.addEventListener('click', function handleClick(event) {
				event.preventDefault();
				if (cMenuItem.hasAttribute('data-menu-id')) {
					const cMenuSubId = cMenuItem.dataset.menuId;
					const cMenuSub = document.querySelector("ul[data-menu-id='" + cMenuSubId + "']");
					if (cMenuSub) {
						if (cMenuSub.hasAttribute('data-parent-menu-id')) {
							const cMenuId = cMenuSub.dataset.parentMenuId;
							const cMenu = document.querySelector("ul[data-menu-id='" + cMenuId + "']");
							if (cMenu) {
								cMenu.classList.remove('cmenu__shift--none');
								cMenu.classList.add('cmenu__shift--left');
								cMenuSub.classList.remove('cmenu__shift--right');
								cMenuSub.classList.add('cmenu__shift--none');
								cMenuSub.classList.add('cmenu--visible');
							}
						}
					}
				}
			});
		});

		const cMenuSubItems = document.querySelectorAll('.cmenu__back-button');
		cMenuSubItems.forEach(cMenuSubItem => {
			cMenuSubItem.addEventListener('click', function handleClick(event) {
				event.preventDefault();
				const cMenuSub = cMenuSubItem.closest(".cmenu__list");
				if (cMenuSub) {
					if (cMenuSub.hasAttribute('data-parent-menu-id')) {
						const cMenuId = cMenuSub.dataset.parentMenuId;
						const cMenu = document.querySelector("ul[data-menu-id='" + cMenuId + "']");
						if (cMenu) {
							// wait for transition
							cMenuSub.addEventListener(transitionEndEventName, onTransitionEnd(cMenuSub));
							// add and remove classes
							cMenu.classList.remove('cmenu__shift--left');
							cMenu.classList.add('cmenu__shift--none');
							cMenuSub.classList.remove('cmenu__shift--none');
							cMenuSub.classList.add('cmenu__shift--right');
						}
					}
				}
			});
		});


		const showMenu = function () {
			const cMenuContainer = document.querySelector("#cmenu");
			cMenuContainer.style.display = 'block';
		};

		const resetMenu = function () {
			const cMenu = document.querySelector("ul[data-menu-id='1']");

			if (cMenu) {
				const menuItems = document.querySelectorAll(".cmenu__list");
				menuItems.forEach(menuItem => {
					if (menuItem.hasAttribute('data-menu-id')) {
						const menuItemId = menuItem.dataset.menuId;
						if (menuItemId > 1) {
							menuItem.classList.remove('cmenu__shift--none');
							menuItem.classList.add('cmenu__shift--right');
						} else {
							menuItem.classList.remove('cmenu__shift--left');
							menuItem.classList.add('cmenu__shift--none');
						}
					}
				});
			}
			const cMenuContainer = document.querySelector("#cmenu");
			cMenuContainer.style.display = 'none';
		};


		const myOffcanvas = document.getElementById('sideMenuLeft');
		myOffcanvas.addEventListener('show.bs.offcanvas', showMenu);
		myOffcanvas.addEventListener('hidden.bs.offcanvas', resetMenu);

		const onTransitionEnd = function (el) {
			let handler = function (event) {
				// hide cMenuSub when transition is done
				el.classList.remove('cmenu--visible');
				// remove this listener
				el.removeEventListener(transitionEndEventName, handler);
			};
			return handler;
		};

		function getTransitionEndEventName() {
			const transitions = {
				"transition": "transitionend",
				"OTransition": "oTransitionEnd",
				"MozTransition": "transitionend",
				"WebkitTransition": "webkitTransitionEnd"
			}
			let bodyStyle = document.body.style;
			for (let transition in transitions) {
				if (bodyStyle[transition] != undefined) {
					return transitions[transition];
				}
			}
		}
	})();

	/* ~~~~~~~~~~~~~~~~ Collection Carousel ~~~~~~~~~~~~~~~~ */
	(function () {
		let carouselItems = document.querySelectorAll('.js-carousel-item')
		carouselItems.forEach((el) => {
			const minPerSlide = 4
			let next = el.nextElementSibling
			for (let i = 1; i < minPerSlide; i++) {
				if (!next) {
					next = carouselItems[0]
				}
				let cloneChild = next.cloneNode(true)
				el.appendChild(cloneChild.children[0])
				next = next.nextElementSibling
			}
		});
	})();
});


